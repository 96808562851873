import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo"

const terms = [
  "The content of the pages of this website is for your general information and use only. It is subject to change without notice.",
  "This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties.",
  "Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.",
  "Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.",
  "This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.",
  "All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.",
  "Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.",
  "From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).",
  "Your use of this website and any dispute arising out of such use of the website is subject to international laws in addition to the laws of the United States of America as well as state and local laws where applicable.",
];

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Terms"
        description="By continuing to browse and use 729 Solutions website, you are agreeing to comply with and be bound by the following terms and conditions of use."
      />
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Terms of Use</h2>
              <p>
                Welcome to 729Solutions.com, the company website for 729
                Solutions. By continuing to browse and use this website, you are
                agreeing to comply with and be bound by the following terms and
                conditions of use, which together with our privacy policy govern
                729 Solutions’ relationship with you in relation to this
                website. If you disagree with any part of these terms and
                conditions, please do not use our website.
              </p>
              <p>
                The term ‘729 Solutions’, ‘729Solutions.com’, ‘us’ or ‘we’
                refers to the owner of the website whose registered office is
                1618 Sullivan Ave, Suite 535, Daly City, CA 94015. The term
                ‘you’ refers to the user or viewer of our website.
              </p>
              <p>
                The use of this website is subject to the following terms of
                use:
              </p>
              <ul className="text-primary">
                {terms.map((term) => (
                  <li className="mb-3">
                    <span className="text-dark">{term}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
